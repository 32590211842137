<template>
    <div class="container" v-permission="'declarations:lis'">
        <header class="jumbotron">
            <h3>Deklaracje</h3>
        </header>
        <div style="display: inline-block;">
            <Button type="button" label="Dodaj" v-on:click="addForm"/>
            <Button type="button" class="p-button-help" label="Importuj" @click="openImportDialog"/>
            <Dialog header="Import danych z pliku" :closable="false" :draggable="true" :position="'top'" footer="Footer"
                    v-model:visible="displayImportDialog" :contentStyle="{height: 'auto'}">
                <div>
                    <div v-for="format of importFormats" :key="format.key" class="field-radiobutton">
                        <div style="display: inline-flex;">
                            <RadioButton :id="format.key" :name="format.name" :value="format.key"
                                         v-model="importFormat"></RadioButton>
                            <label :for="format.key" :style="{'padding-left': '10px'}">{{format.name}}</label>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <Button label="Zamknij" icon="pi pi-times" @click="closeImportDialog" class="p-button-text"/>
                    <div style="display: inline-block;">
                        <FileUpload
                                class="p-button-help" mode="basic" chooseLabel="Wybierz plik" accept="text/xml, .xls"
                                name="file"
                                :url="uploadUrl" @before-upload="onBeforeUpload"
                                @before-send="onBeforeSend" @upload="onUpload" @error="onError"/>
                    </div>
                </template>
            </Dialog>
        </div>
        <!--        <Button type="button" class="p-button-help" label="Importuj"/>-->
        <div style="height: calc(100vh - 143px)">
            <DataTable :value="content" :lazy="true" :paginator="true" :rows="lazyParams.limit"
                       :totalRecords="totalRecords"
                       @page="onPage($event)" :loading="loading" responsiveLayout="scroll" class="padding3"
                       :scrollable="true" scrollHeight="flex">
                <Column field="place" header="Miejscowość"></Column>
                <Column field="zipCode" header="Kod pocztowy"></Column>
                <Column field="street" header="Ulica"></Column>
                <Column field="buildingNumber" header="Nr domu"></Column>
                <Column field="apartmentNumber" header="Nr mieszkania"></Column>
                <Column field="residents" header="Liczba mieszkańców"></Column>
                <Column headerStyle="width: 12em" bodyStyle="text-align: center; display: inline-block;">
                    <template #body="slotProps">
                        <Button type="button" class="p-button-sm" label="Edytuj"
                                v-on:click="editForm(slotProps.data.id)"></Button>
                        <Button type="button" class="p-button-danger p-button-sm" label="Usuń"
                                v-on:click="removeForm(slotProps.data.id)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>

    import DeclarationService from '../../../services/declaration.service';
    import TokenService from '../../../services/token.service';
    import {watch, ref} from "vue";

    export default {
        name: "DeclarationList",
        inject: ['global'],
        data() {
            return {
                loading: false,
                totalRecords: 0,
                content: "",
                meta: null,
                lazyParams: {
                    limit: 100,
                    page: 1
                },
                displayImportDialog: false,
                importFormat: 'excel',
                uploadUrl: `${window.location.origin}${this.global.state.instancePatch}/api/declarations/upload`, //TODO get url fro service
                importFormats: ref([
                    {name: 'Excel', key: 'excel'},
                ]),
                filter: {
                    quarter: this.global.state.selectedPeroid.quarter,
                    year: this.global.state.selectedPeroid.year
                },
                selectedPeroid: ref(this.global)
            };
        },
        mounted() {
            this.loading = true;
            watch(this.selectedPeroid, (val) => {
                this.filter = {
                    quarter: val.state.selectedPeroid.quarter,
                    year: val.state.selectedPeroid.year
                };
                this.getList();
            });
            this.getList();
        },
        methods: {
            openImportDialog() {
                this.displayImportDialog = true;
            },
            closeImportDialog() {
                this.displayImportDialog = false;
            },
            onBeforeSend(request) {
                request.formData.set('quarter', this.global.state.selectedPeroid.quarter);
                request.formData.set('year', this.global.state.selectedPeroid.year);
                request.formData.set('format', this.importFormat);
                request.xhr.setRequestHeader('Authorization', TokenService.getLocalAccessToken());
                return request;
            },
            onUpload() {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Sukces',
                    detail: 'Poprawnie zaimportowano plik',
                    life: 3000
                });
                this.displayImportDialog = false;
                this.getList();
            },
            onError(error) {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Błąd',
                    detail: 'Nie zaimportowano pliku',
                    life: 3000
                });
            },
            onPage(event) {
                console.log(event);
                this.lazyParams.page = event.page + 1;
                this.getList();
            },
            getList: function () {
                this.loading = true;
                DeclarationService.getAllDeclarations(this.lazyParams.limit, this.lazyParams.page, this.filter).then(
                    (response) => {
                        this.content = response.data.items;
                        this.meta = response.data.meta;
                        this.totalRecords = this.meta.totalItems;
                        this.loading = false;
                        console.log(this.meta);
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            addForm: function () {
                this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/deklaracje/dodaj`});
            },
            editForm: function (id) {
                this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/deklaracje/edytuj/${id}`});
            },
            removeForm: function (id) {
                DeclarationService.removeDeclaration(id).then(
                    () => {
                        this.getList();
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Usunięto',
                            detail: 'Poprawnie usunięto pozycję',
                            life: 3000
                        });
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        }
    };
</script>
